import React, { useState, useEffect } from "react";
import { Routes, Route} from "react-router-dom";
import MainPage from "./components/mainPage/MainPage";
import SoftDrinks from "./components/beverages/softdrinks/SoftDrinks";
import Liquors from "./components/beverages/liquors/Liquors";
import Abarrotes from "./components/grains/abarrotes/Abarrotes";
import Snacks from "./components/grains/snacks/Snacks";

/**********************
 * App
 *********************/
function App() {
  const [pwaData, setPwaData] = useState(null);
  const [runsOnce, setRunsOnce] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if (runsOnce && isMounted) {
      // event object that contains information about the prompt installation
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        console.log("before installation prompt fired");
        let installPrompt = event;
        // store event object for later use
        setPwaData(installPrompt);
        setRunsOnce(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [pwaData, runsOnce]);


  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage pwa={pwaData} />} />
        <Route path="bebidas" element={<SoftDrinks />} />
        <Route path="licores" element={<Liquors />} />
        <Route path="snacks" element={<Snacks />} />
        <Route path="abarrotes" element={<Abarrotes />} />
      </Routes>
    </>
  );
}

export default App;
