import { Enums } from "../miscellaneous/emuns/emuns";
import { urlBased64ToUnit8Array } from "./utilities";

///////////////////////////////////////////
// Variables from Enums
const notifications_public_key = Enums.SEND_NOTIFICATION_PUBLIC_KEY;
let options = {
  body: "Notificaciones Activadas",
  icon: "../../public/favicon-32x32.png",
};
///////////////////////////////////////////////
function displayConfirmation(options) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(function (swn) {
      swn.showNotification("La Villa", options);
    });
  }
}

// the action key must be created to use the value
export function clickNotification(event) {
  let notification = event.notification;
  let action = event.action;
  console.log(notification);

  // confirm is the value from key 'action' provided in the actions in the
  // display notifications
  if (action === "confirm") {
    notification.close();
  } else {
    event.waitUntil();
  }
}

// exported to Notifications.js
export function configurePushNotification() {
  if (!("serviceWorker" in navigator)) {
    return;
  }
  let reg;

  navigator.serviceWorker.ready
    .then(function (swreg) {
      reg = swreg;
      //  https://developer.mozilla.org/en-US/docs/Web/API/PushManager/getSubscription
      return swreg.pushManager.getSubscription();
    })
    .then(function (sub) {
      if (sub === null) {
        let convertVapidKeyOuter = urlBased64ToUnit8Array();
        let convertVapidKeyInner = convertVapidKeyOuter(
          notifications_public_key
        );
        // https://developer.mozilla.org/en-US/docs/Web/API/PushManager/subscribe
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertVapidKeyInner,
        });
      }
    })
    // returns a new subcription to store it in the database
    .then(function (newSub) {
      return fetch(
        "https://almacen-villa-default-rtdb.firebaseio.com/notificationSub.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // returns the values from the subcription to the database
          // newSub containers all the information
          body: JSON.stringify(newSub),
        }
      );
    })
    .then(function (res) {
      if (res.ok) {
        displayConfirmation(options);
      }
    })
    .catch(function (err) {
      // error [for registration failed - push services: check privacy and security,
      // make sure that use google services for push messaging is activated]
      console.log(err.message);
    });
}
