import { Enums } from "../../miscellaneous/emuns/emuns";
const licores = Enums.LICORES;
const bebidas = Enums.BEBIDAS;
const snacks = Enums.SNACKS;
const abarrotes = Enums.ABAROTES;

/********************
 * dynamic used of links for MainPage.js
 *******************/
export const array1 = [
  {
    link: licores,
    title: licores,
  },
  {
    link: bebidas,
    title: bebidas,
  },

  {
    link: snacks,
    title: snacks,
  },
  {
    link: abarrotes,
    title: abarrotes,
  },
];
