import { db } from "./firebase";
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  collectionGroup,
  writeBatch,
} from "firebase/firestore";

/*********************************************************
 * Firebase module
 * closure tecnique (encapsulation)
 *********************************************************/
export default function FirebaseModule() {
  

  ///////////////////////////////////////////////////////////////
  // Encapsulation tecnique (Closures) to use in conjunction with
  // the public functions starting in line 83
  // https://firebase.google.com/docs/firestore/query-data/get-data
  async function checkDataFromDatabase(pathCollec, docId) {
    if (pathCollec && docId) {
      const ref = doc(db, `/${pathCollec}/${docId}`);
      const snapshot = await getDoc(ref);
      return snapshot;
    }
  }
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  async function addDataToDatabase(pathCollec, documentID, dataProvided) {
    if (documentID && pathCollec) {
      // documentID is the document (reference or name) in firebase
      await setDoc(doc(db, pathCollec, documentID), dataProvided);
    }
  }
  // ref: https://firebase.google.com/docs/firestore/query-data/queries#simple_queries
  // checks validation from the already data in the database from the id object
  // keyInDatabase works like a hashmap or dictionary, the key will return the value id: 'value'
  async function getDocFromDatabase(pathCollec, keyInDatabase, valueToCompare) {
    if (keyInDatabase && valueToCompare) {
      const docRef = collection(db, pathCollec);
      const q = query(docRef, where(keyInDatabase, "==", valueToCompare));
      const snapShotQuery = await getDocs(q);
      return snapShotQuery; // returns a promise.then(() => {})... it needs to be iterated again
    }
  }

  /****************************************************
   * gets all the documents based on the condition
   * const docsData = queryshotQuery.docs.map(doc => ({
   * id: doc.id,
   * ...doc.data()}))
   * setData(docsData); 
   ***************************************************/

  //https://firebase.google.com/docs/firestore/query-data/get-data#get_all_documents_in_a_collection
  async function getAllDocsFromDatabase(pathCollection) {
    if (pathCollection) {
      const ref = await getDocs(collection(db, pathCollection));
      return ref;
    }
  }
  // creates a unique document id in firebase with addDoc
  // https://firebase.google.com/docs/firestore/manage-data/add-data
  // updates the document with the autogenerated id
  async function addDocWithIDToDatabase(pathCollection, dataProvided) {
    if (pathCollection) {
      const ref = await addDoc(collection(db, pathCollection), dataProvided);
      const docId = ref.id;
      updateDocToDatabase(pathCollection, docId, {
        id: docId,
      });
    }
  }

  // https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
  async function updateDocToDatabase(pathCollection, documentId, data) {
    const documentRef = doc(db, pathCollection, documentId);
    const ref = await updateDoc(documentRef, data);
    return ref;
  }

  // https://firebase.google.com/docs/firestore/manage-data/delete-data
  async function deleteDocFromDatabase(pathCollection, docId) {
    await deleteDoc(doc(db, pathCollection, docId));
  }

  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  // deletes a group of documents based on the conditions after certain amount of time
  async function deleteBatchDocsFromDatabase(
    pathCollection,
    conditionfield,
    conditionValue
  ) {
    const batch = writeBatch(db);
    const querySnap = await collectionGroup(pathCollection)
      .where(conditionfield, "<", conditionValue)
      .get();

    querySnap.forEach((doc) => {
      batch.delete(doc.red);
    });

    await batch.commit();
  }
  ///////////////////////////////////////////////////////////////
  // will invoke an instance of the closure module,
  function checkDataInFirebase(pathCollec, docId) {
    return checkDataFromDatabase(pathCollec, docId);
  }

  function addDataInFirebase(pathCollec, valueID, dataProvided) {
    return addDataToDatabase(pathCollec, valueID, dataProvided);
  }

  function getDocInFirebase(pathCollec, keyInDatabase, valueToCompare) {
    return getDocFromDatabase(pathCollec, keyInDatabase, valueToCompare);
  }

  function getAllDocsFromFirebase(pathCollection) {
    return getAllDocsFromDatabase(pathCollection);
  }

  function addDocumentWithFirebaseID(pathCollection, dataProvided) {
    return addDocWithIDToDatabase(pathCollection, dataProvided);
  }

  function updateDocToFirebase(pathCollection, documentId, data) {
    return updateDocToDatabase(pathCollection, documentId, data);
  }
  function deleteDocFromFirebase(pathCollection, documentId) {
    return deleteDocFromDatabase(pathCollection, documentId);
  }

  function deleteBatchDocsFromFirebase(
    pathCollection,
    conditionfield,
    conditionValue
  ) {
    return deleteBatchDocsFromDatabase(
      pathCollection,
      conditionfield,
      conditionValue
    );
  }

  return {
    checkDataInFirebase,
    addDataInFirebase,
    getDocInFirebase,
    getAllDocsFromFirebase,
    addDocumentWithFirebaseID,
    updateDocToFirebase,
    deleteDocFromFirebase,
    deleteBatchDocsFromFirebase,
  };
}
