import React, { useState, useEffect } from "react";
import NavigationBar from "../../../miscellaneous/navigationbar/NavigationBar";
import SearchEnginee from "../../../miscellaneous/searchEnginee/SearchEnginee";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { Enums } from "../../../miscellaneous/emuns/emuns";
import Card from "../../../miscellaneous/card/Card";
import "../beverages.scss";

/*********************************
 * SoftDrinks
 ********************************/
export default function SoftDrinks() {
  const firebaseModule = FirebaseModule();
  const [search, setSearch] = useState("");
  const [runsOnce, setRunsOnce] = useState(true);
  const [data, setData] = useState([]);
  const [ok, setOk] = useState(null);
  const softdrinks = Enums.SOFTDRINKS;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      const ref = firebaseModule.getAllDocsFromFirebase(softdrinks);
      ref
        .then((res) => {
          const d = res.docs.map((items) => items.data());
          const f = d.filter((items) => +items.count > 0);
          setData(f);
          setOk(true);
        })
        .catch((error) => {
          console.error(error.message);
        });

      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [runsOnce, firebaseModule, softdrinks]);

  let filteredData = data.filter((item) =>
    item.type.toLowerCase().includes(search.toLowerCase().trim())
  );

  return (
    <div className="beverages-container">
      <NavigationBar />
      <div className="beverages-navigation-wrapper">
        <SearchEnginee
          placeholder={"Bebidas"}
          value={search}
          change={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="beverages-card-container">
        {ok
          ? filteredData.map((item) => {
              return (
                <Card
                  key={item.id}
                  c1={item.url1}
                  c2={item.url2}
                  c3={item.name}
                  c4={item.type}
                  c5={item.price}
                  c6={item.discount}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}
