import React, { useState, useEffect } from "react";
import NavigationBar from "../../../miscellaneous/navigationbar/NavigationBar";
import SearchEnginee from "../../../miscellaneous/searchEnginee/SearchEnginee";
import FirebaseModule from "../../../firebase/FirebaseModule";
import { Enums } from "../../../miscellaneous/emuns/emuns";
import Card from "../../../miscellaneous/card/Card";
import "../grains.scss";

/******************************
 * Snacks
 *****************************/
export default function Snacks() {
  const firebaseModule = FirebaseModule();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [ok, setOk] = useState(null);
  const [runsOnce, setRunsOnce] = useState(true);

  const snacks = Enums.SNACKS;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && runsOnce) {
      const ref = firebaseModule.getAllDocsFromFirebase(snacks);
      ref
        .then((res) => {
          const d = res.docs.map((items) => items.data());
          const f = d.filter((items) => + items.count > 0);
          setData(f);
          setOk(true);
        })
        .catch((error) => {
          console.error(error.message);
        });

      setRunsOnce(false);
    }

    return () => {
      isMounted = false;
    };
  }, [runsOnce, firebaseModule, snacks]);

  let filteredData = data.filter((item) =>
    item.type.toLowerCase().includes(search.toLowerCase().trim())
  );
  return (
    <div className="grains-container">
      <NavigationBar />
      <div className="grains-navigation-wrapper">
        <SearchEnginee placeholder={"tipo de snack"} />
      </div>
      <div className="grains-card-container">
        {ok
          ? filteredData.map((item) => {
              return (
                <Card
                  key={item.id}
                  c1={item.url1}
                  c2={item.url2}
                  c3={item.name}
                  c4={item.type}
                  c5={item.price}
                  c6={item.discount}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}
