import React from "react";
import { getIcon } from "./icon";
import "./icons.scss";

export default function Icons({ clicked, iconName }) {
  const icon = getIcon(iconName);
  return (
    <button className="icon-button-container" onClick={clicked}>
      {icon}
    </button>
  );
}
