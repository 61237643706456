import React from 'react'
import './main-page.scss';


/**************************
 * Offers
 * exported to MainPage.js
 *************************/
export default function Offers() {
  return (
    <div className='main-page-offers'>Offers</div>
  )
}
