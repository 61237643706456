import React from "react";
import Swiper from "../swiper/Swiper";
import "./card.scss";

/***********************
 * Card
 **********************/
export default function Card({ c1, c2, c3, c4, c5, c6, c7, c8 }) {
  // c6 is the discount price and c5 is the original price
  let price =
    c6 > 0 ? (
      <h4>
        Precio:<span>{` ¢${c5}`}</span>
      </h4>
    ) : (
      <h4>{`Precio: ¢${c5}`}</h4>
    );
  let discount = c6 > 0 ? <h4>Precio con descuento:{` ¢${c6}`}</h4> : null;
  return (
    <div className="card-container">
      <div className="card-photo-wrapper">
        <Swiper images={[c1, c2]} />
      </div>
      <div className="card-info-wrapper">
        <div>
          <h2>{c3}</h2>
          <h3>{c4}</h3>
        </div>
        <div>
          {price}
          {discount}
        </div>
      </div>
    </div>
  );
}
