import React from "react";
import "./search-engine.scss";

/*********************************
 * SearchEnginee
 *******************************/
export default function SearchEnginee({ value, change, placeholder }) {
  return (
    <div className={"search-engine-main"}>
      <input
        className={"search-engine-container"}
        value={value}
        type="search"
        
        onChange={change}
        placeholder={`Buscar ${placeholder}`}
      />
      <div className={"search-engine-wrapper"}>
        <div className={"search-engine-deco"} />
      </div>
    </div>
  );
}
