import React from "react";
import { Link } from "react-router-dom";
import { array1 } from "./array";
import "./main-page.scss";

/**********************************
 * MenuContent
 * exported to MainMenu.js
 *********************************/
export default function MenuContent() {
  return (
    <div className="main-page-subnav">
        <h4>Menú</h4>
      {array1.map((item) => {
        return (
          <Link
            key={item.title}
            to={item.link}
            className="button-link-wrapper"
            style={{ marginBottom: "1em" }}
          >
            {`${item.title}........`}
          </Link>
        );
      })}
    </div>
  );
}
