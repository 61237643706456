import React, { useState } from "react";

import villalogo from "../../miscellaneous/logos/villablack.png";
import Icons from "../../miscellaneous/icons/Icons";
import Modal from "../../modal/Modal";
import NavigationModal from "../../miscellaneous/navigationbar/NavigationModal";
import PwaInstallation from "../application/PwaInstallation";
import AppService from "../application/AppService";
import { Enums } from "../../miscellaneous/emuns/emuns";
import Offers from "./Offers";
import MenuContent from "./MenuContent";
import "./main-page.scss";
import "../../miscellaneous/buttonsStyle/button-styles.scss";

/*****************************
 * MainPage
 * exported to App.js
 ****************************/
export default function MainPage({ pwa }) {
  const [openModal, setOpenModal] = useState(false);
  const [modalOption, setModalOption] = useState(null);
  const option1 = Enums.OFFERS;
  const option2 = Enums.NOTI;
  const menu = Enums.MENU;
  const number = Enums.PHONE_NUMBER;

  function onHandlerModal(str) {
    setOpenModal((prev) => !prev);
    setModalOption(str);
  }

  return (
    <>
      <div className="main-page-container">
        <nav>
          <Icons iconName={"menuIcon"} clicked={() => onHandlerModal(menu)} />
        </nav>

        <div className="main-page-logo">
          <img src={villalogo} alt="villa" />
          <h4>Almacén La Villa</h4>
          <div className="main-page-small-divider" />
        </div>
        <address>
          125 mts Este de la Central del ICE, <br />
          Villa Colon, San José <br /> <br />
          <div className="main-page-phone">
            <a href={number} target="_blank" rel="noopener noreferrer">
              <h5>Contactenos</h5>
              <Icons iconName={"phoneIcon"} />
            </a>
          </div>
        </address>

        <PwaInstallation pwa={pwa} />
        <Icons iconName={"notiIcon"} clicked={() => onHandlerModal(option2)} />

        <div className="main-page-raffle">
          <button
            className="button-border-bottom"
            onClick={() => onHandlerModal(option1)}
          >
            Offertas del Mes
          </button>
        </div>
        <div className="main-page-disclaimer">
          <div>
            <span>
              Todos los derechos reservados. {new Date().getFullYear()}© Almacén
              La Villa
            </span>
          </div>
          <a
            href={"https://deft-concha-77ac54.netlify.app/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icons iconName={"exclamationIcon"} />
          </a>
        </div>
      </div>
      <Modal openModal={openModal}>
        <NavigationModal click={() => setOpenModal((prev) => !prev)} />
        <div className="main-page-modal-container">
          {modalOption === option1 ? <Offers /> : null}
          {modalOption === option2 ? <AppService /> : null}
          {modalOption === menu ? <MenuContent />: null}
        </div>
      </Modal>
    </>
  );
}
