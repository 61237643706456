import React from "react";
import ReactDOM from "react-dom";
import "./modal.scss";

/*****************************
 * Modal
 ****************************/
export default function Modal({ openModal, children }) {
  if (!openModal) return null;
  return ReactDOM.createPortal(
    <>
      <div className="modal-background" />
      <div className="modal-style">{children}</div>
    </>,
    document.getElementById("portal")
  );
}
