export function urlBased64ToUnit8Array() {
    function urlBaseUnit8(base64String) {
      let padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      let based64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
  
      let rawData = window.atob(based64);
      let outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }
  
    return urlBaseUnit8;
  }