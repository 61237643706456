import React, { useRef, useEffect } from "react";
import { uniqueId } from "../../miscellaneous/uuid/uuid";
import './swiper.scss';
/*******************************
 * Swiper
 * exported to Card.js
 ******************************/
export default function Swiper({ images }) {
  const swiperRef = useRef();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      swiperRef.current.addEventListener("swiperprogress", (e) => {
        const [swiper, progress] = e.detail;
        // console.log(progress);
      });

      swiperRef.current.addEventListener("swiperslidechange", (e) => {
        // console.log("slide change");
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <swiper-container
      class="mySwiper"
      ref={swiperRef}
      slides-per-view="1"
      navigation="true"
      zoom="true"
      pagination="true"
      pagination-clickable="true"
    >
      {images
        ? images.map((items) => {
            return (
              <swiper-slide key={uniqueId()}>
                <div className="swiper-image-control">
                  <img src={items} alt="villa" />
                </div>
              </swiper-slide>
            );
          })
        : null}
    </swiper-container>
  );
}
