// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
// makes existing properties non-writable and non-configurable
// the value cannot be changed
export const Enums = Object.freeze({
  HOME: "/",
  SNACKS: "snacks",
  ABAROTES: "abarrotes",
  LICORES: "licores",
  BEBIDAS: "bebidas",
  LIQUOR: "liquors",
  SOFTDRINKS: "softdrinks",
  MENU: "menu",
  OFFERS: "offers",
  GRAINS: "grains", // grains is abarrotes
  PHONE_NUMBER: "tel:+50622491409",
  NOTI: "noti", // used in MainPage.js "comparison"
  SEND_NOTIFICATION_PUBLIC_KEY:
    "BDSNuCuo-_e2f7lFVprIoS1WKv7pL9jPs5gzIzkFeVfR-dBT4gnIDR-23fy8LeCnYbXXTCvO3hs1ZM157Jn6F88",
});
