import { AiOutlineWhatsApp } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { BsExclamationCircle } from "react-icons/bs";
import { RiMenuFoldLine } from "react-icons/ri";
import { MdOutlineNotificationAdd } from "react-icons/md";
import "./icons.scss";

/***************************
 * Icon Function
 **************************/
export function getIcon(iconName) {
  switch (iconName) {
    case "whatsappIcon":
      return <AiOutlineWhatsApp className="icon-whatapp" />;
    case "backIcon":
      return <RiArrowGoBackFill className="icon-back" />;
    case "phoneIcon":
      return <FiPhoneCall className="icon-phone" />;
    case "exclamationIcon":
      return <BsExclamationCircle className="icon-exclamation" />;
    case "menuIcon":
      return <RiMenuFoldLine className="icon-menu" />;
    case "notiIcon":
      return <MdOutlineNotificationAdd className="icon-noti" />;
    default:
      return null;
  }
}
