import React, { useState, useEffect } from "react";
import "../../miscellaneous/buttonsStyle/button-styles.scss";
import { RxQuestionMarkCircled } from "react-icons/rx";
import NavigationModal from "../../miscellaneous/navigationbar/NavigationModal";
import Modal from "../../modal/Modal";
import menu_icon from "../../miscellaneous/images/menu_icon.png";
import menu_options from "../../miscellaneous/images/browser_menu_option.png";
import "./application.scss";
/*************************************
 * PwaInstallation.js
 * exported to Application.js
 ************************************/
export default function PwaInstallation({ pwa }) {
  const [installed, setInstalled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const buttonTitle = {
    title: "Bajar App",
  };

  /*************************************/
  // this execution will provide the check if pwa was installed to hide the button
  function handlerCheckInstalation() {
    const apiBoolean = window.matchMedia("(display-mode: standalone)").matches; // returns a boolean
    return apiBoolean;
  }

  // The cleanup function within the useEffect sets the isMounted variable to false,
  // indicating that the component is unmounted. This way,
  // when the component unmounts, the isMounted flag is updated,
  // and any subsequent updates or state setting inside the async function are ignored
  useEffect(() => {
    let isMounted = true;

    async function checkForInstalltion() {
      if (!installed) {
        const result = handlerCheckInstalation();
        if (isMounted) setInstalled(result);
      }
    }
    checkForInstalltion();

    return () => {
      isMounted = false;
    };
  }, [installed]);

  //  fires the promp() function from the browser
  // console.log(!(!pwaContext));
  // console.log(installedRelatedApps());
  function installpwa() {
    if (pwa) {
      pwa.prompt();
    }
  }

  // causes the button to vanish when the application is installed
  let checkInstallation = !installed ? (
    <>
      <div className="application-buttons-wrapper">
        <button className="button-wrapper-app" onClick={installpwa}>
          {buttonTitle.title}
        </button>
        <RxQuestionMarkCircled
          className="application-icon-notif"
          onClick={() => setOpenModal((prev) => !prev)}
        />
      </div>
    </>
  ) : null;

  return (
    <>
      {checkInstallation}
      <Modal openModal={openModal}>
        <div className="application-modal-container">
          <NavigationModal click={() => setOpenModal((prev) => !prev)} />
          <div className="application-modal-text">
            <h4> Los navegadores Safari and Firefox no admiten esta función</h4>
            <h4>
              Para la instalacion en iPhones, es necesario realizar el
              procedimiento de forma manual; seleccione 'Añadir a la pantalla de
              inicio' o 'add to home screen' desde el menú del navegador en su dispositivo móvil.
            </h4>
              <img src={menu_icon} alt="menuicon" />
          </div>
          <div className="application-modal-images">
           
            
            
              <img src={menu_options} alt="menuoptions" />
           
          </div>
        </div>
      </Modal>
    </>
  );
}
