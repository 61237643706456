import React from "react";
import Icons from "../icons/Icons";
import navigationlogo from "../logos/navigationlogo.png";
import "./navigaton-bar.scss";

/**********************************
 * NavigationBar
 *********************************/
export default function NavigationModal({ click }) {
  return (
    <div className="navigation-bar-container">
      <div className="navigation-bar-arrow-wrapper">
        <Icons iconName={"backIcon"} clicked={click} />
      </div>
      <div className="navigation-bar-logo-wrapper">
        <img src={navigationlogo} alt="logo" />
      </div>
    </div>
  );
}
