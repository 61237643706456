import React, { useState, useEffect } from "react";
import { configurePushNotification } from "../../serviceWorker/sw-notification";
import "../../miscellaneous/buttonsStyle/button-styles.scss";

/*********************************
 * Notification
 ********************************/
export default function Notification() {
  const [requestPermission, setRequestPermission] = useState(false);
  const [runsOnce, setRunsOnce] = useState(true);
  const [currentPermisionState, setCurrentPermissionState] = useState(
    "Activar Notificaciones"
  );

  //////////////////////////////////////////////////////
  // this function will be triggered if the userRequest variable is true
  async function requestNotification() {
    if (!("Notification" in window)) {
      alert("This browser does not support notifications");
    } else {
      window.Notification.requestPermission().then((permission) => {
        if (permission !== "denied") {
          setRequestPermission(true);
          configurePushNotification();
        } else if (permission === "denied") {
          setCurrentPermissionState("Notificationes denegadas por el usuario");
        }
      });
    }
  }

  // The cleanup function within the useEffect sets the isMounted variable to false,
  // indicating that the component is unmounted. This way,
  // when the component unmounts, the isMounted flag is updated,
  // and any subsequent updates or state setting inside the async function are ignored
  useEffect(() => {
    let isMounted = true;

    function promiseFetch() {
      if (isMounted && runsOnce) {
        const permission = window.Notification.permission;
        if (permission === "granted") {
          setRequestPermission(true);
          setRunsOnce(false);
        } else {
          setRunsOnce(false);
        }
      }
    }
    promiseFetch();

    return () => {
      isMounted = false;
    };
  }, [runsOnce]);

  // this function will trigger the request from the user for the notification request
  function handlerNotificationPermission() {
    requestNotification();
  }

  //////////////////////////////////////////////////////
  // requestPermission variable for the buttom only
  return (
    <>
      {!requestPermission ? (
        <>
          <button
            className="button-application-wrapper"
            onClick={() => handlerNotificationPermission()}
          >
            {currentPermisionState}
          </button>
        </>
      ) : (
        <button className="button-application-wrapper">
          Notificaciones Activadas
        </button>
      )}
    </>
  );
}
