import React from "react";
import Notification from "./Notification";
import "./application.scss";
/**********************************
 * Application
 * exported to MainPage.js
 *********************************/
export default function AppService() {
  return (
    <div className="application-container">
      <div className="application-wrapper">
        <Notification />
      </div>
    </div>
  );
}
